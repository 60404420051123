import React, { createContext, useReducer, useEffect } from 'react';

export const ProductContext = createContext();

const productReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
};

export const ProductProvider = ({ children }) => {
  const initialState = {
    products: [],
  };

  const [state, dispatch] = useReducer(productReducer, initialState);

  useEffect(() => {
    // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
    fetch('YOUR_API_ENDPOINT')
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: 'SET_PRODUCTS', payload: data });
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);

  return (
    <ProductContext.Provider value={{ products: state.products }}>
      {children}
    </ProductContext.Provider>
  );
};
