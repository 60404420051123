import React, { useState, Fragment, useContext } from "react";
import { UserAuth } from "../context/AuthContext";
import {useNavigate, Link  } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CartContext } from "../context/CartContext";
import {HiOutlineUser } from "react-icons/hi";



const Navbar = () => {
  const [open, setOpen] = useState(false);
  const { user, logOut } = UserAuth();
  const { cartItems } = useContext(CartContext)
    let navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>

      <div className="bg-white">
        {/* Mobile menu */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                  <div className="flex px-4 pb-2 pt-5">
                    <button
                      type="button"
                      className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                      onClick={() => setOpen(false)}
                    >
                     
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                    {user ? (
                      <div className="flow-root">
                        <Link to="/account">
                          <button className="-m-2 block p-2 font-medium text-gray-900">
                            Account
                          </button>
                        </Link>
                        <button
                          onClick={handleLogOut}
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          LogOut
                        </button>
                      </div>
                    ) : (
                      <div className="flow-root">
                        <Link to="/logIn">
                          <button className="-m-2 block p-2 font-medium text-gray-900">
                            Sign In
                          </button>
                        </Link>
                        <Link
                          to="/signUp"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          <button>Register</button>
                        </Link>
                      </div>
                    )}
                  </div>

                  <div className="border-t border-gray-200 px-4 py-6">
                    <div className="-m-2 flex items-center p-2">
                      <img
                        src="/images/naija.png"
                        alt=""
                        className="block h-auto w-5 flex-shrink-0"
                      />
                      <span className="ml-3 block text-base font-medium text-gray-900">
                        NGN
                      </span>
                      <span className="sr-only">, change currency</span>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <header className="relative bg-white">
         {/* <Head /> */}

          <nav
            aria-label="Top"
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            <div className="border-b border-gray-200">
              <div className="flex h-16 items-center">
                <button
                  type="button"
                  className="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Logo */}
                <div className="ml-4 flex items-baseline py-16 lg:ml-0 gap-2">
                  <Link to="/" className="flex gap-2 items-center">
                    <img src="./oplogo.png" alt="Company Logo" className="flex h-[60px] w-[60px] "/>
                    <p className="text-xl md:text-2xl font-bold cursor-pointer text-[#e2312b]">
                     Optasiaplus
                    </p>
                  </Link>
                </div>
                <div className="ml-auto flex items-center">
                {user ? (
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <Link to="/account"><button className="flex gap-1 items-center text-sm font-medium text-gray-500 hover:text-gray-800">
                      <HiOutlineUser size={26}/>
                    
                    </button></Link>
                    <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                    <button onClick={handleLogOut} className="text-sm font-medium text-gray-700 hover:text-gray-800">
                      Log Out
                    </button>
                    </div>
                    ) : (
                    <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <Link to="/logIn"><button className="text-sm font-medium text-gray-700 hover:text-gray-800">
                      Sign in
                    </button></Link>
                    <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                    <Link to="/signUp"><button className="text-sm font-medium text-gray-700 hover:text-gray-800">
                      Create account
                    </button></Link>
                  </div>
                  )} 

                  <div className="hidden lg:ml-8 lg:flex">
                    <div
                      className="flex items-center text-gray-700 hover:text-gray-800"
                    >
                      <img
                        src="/images/Naija.png"
                        alt=""
                        className="block h-auto w-5 flex-shrink-0"
                      />
                      <span className="ml-3 block text-sm font-medium">
                        NGN
                      </span>
                      <span className="sr-only">, change currency</span>
                    </div>
                  </div>

                  {/* Search */}
                  <div className="flex lg:ml-6">
                    <div
                      className="p-2 text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">Search</span>
                      <MagnifyingGlassIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  {/* Cart */}
                  <div className="ml-4 flow-root lg:ml-6">
                    <Link to="/cart" className="group -m-2 flex items-center p-2">
                      <ShoppingBagIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                       
                      />
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                       {cartItems.length}
                      </span>
                     
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Navbar;
