import React, {useContext} from "react";
import { CartContext } from "../context/CartContext";

const products = [
    {
      id: 16,
      name: 'Earthen Bottle',
      href: '#',
      price: '48',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-01.jpg',
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 12,
      name: 'Nomad Tumbler',
      href: '#',
      price: '35',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 13,
      name: 'Focus Paper Refill',
      href: '#',
      price: '89',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg',
      imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
    },
    {
      id: 14,
      name: 'Machined Mechanical Pencil',
      href: '#',
      price: '35',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    
  ]
  
  export default function Accessory() {
    const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  
    const handleAddToCart = (phone) => {
      addToCart({ ...phone, quantity: 1 });
    };
  
    const handleRemoveFromCart = (phone) => {
      removeFromCart(phone);
    };

    return (
      <div className="bg-white border-2 border-gray-200 rounded-lg mx-2 md:mx-16 mt-10">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 pb-4">
          Accessories
        </h2>
  
          <div className="grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {products.map((product) => (
              <div key={product.id} className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
                <p className="mt-1 text-lg font-medium text-gray-900">N {product.price}</p>
                {cartItems.some((p) => p.id === product.id) ? (
                  <button className="bg-red-800 w-full py-3 text-slate-100 font-bold text-center hover:bg-red-600" onClick={() => handleRemoveFromCart(product)}>
                    Remove
                  </button>
                ) : (
                  <button className="bg-slate-700 w-full py-3 text-slate-100 font-bold text-center hover:bg-slate-400" onClick={() => handleAddToCart(product)}>
                    Add to Cart
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  