import React from "react";
import Brands from "../component/Brands";
import ProductList from "../component/ProductList";
import Phones from "./Phones";
import FilterAndSearch from "../component/FilterAndSearch";
import Accessory from "./Accessory";
// import Slidebar from "./Slidebar";



// import "../component/slide/styles.css"

const Home = () => {
  return (
    <div>
      <FilterAndSearch />
      {/* <Slidebar /> */}
      <Brands />
      <ProductList />
      <Phones />
      <Accessory />
    </div>
  );
};

export default Home;
