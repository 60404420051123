import React from "react";
import { Routes, Route } from "react-router-dom";
import { CartProvider } from "./context/CartContext";
import Cart from "./component/Cart";
import Navbar from "./component/NavBar";
import { AuthContextProvider } from "./context/AuthContext";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import { ProductProvider } from "./context/ProductContext";
import Footer from "./component/Footer";



const App = () => {
  return (
    <AuthContextProvider>
      <ProductProvider>
      <CartProvider>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/signUp" element={<Register />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </CartProvider>
        <Footer />
      </ProductProvider>
    </AuthContextProvider>
  );
};

export default App;
