import React, { useContext, useState } from "react";
import { ProductContext } from "../context/ProductContext";

const FilterAndSearch = () => {
  const { products } = useContext(ProductContext);
  const [category, setCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.category.includes(category) &&
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-full bg-white py-8 flex items-center justify-center mx-auto gap-2">
      <div className=" right-0 flex items-center rounded-md shadow-sm">
        <label>
          <select
            value={category}
            onChange={handleCategoryChange}
            className="h-full rounded-md border-0 bg-white py-3 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
          >
            <option value="">All categories</option>
            <option value="Computers">Laptop & Desktop</option>
            <option value="Phones">Phones & Tablets</option>
            <option value="Accessories">Accessories</option>
          </select>
        </label>
      </div>
      <div className="rounded-md shadow-sm">
        <label>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="block w-full md:w-[400px] rounded-md border py-3 pl-2 text-gray-900 placeholder:text-gray-400 bg-gray-50 sm:text-sm sm:leading-6"
        
          />
        </label>
      </div>
      <ul>
        {filteredProducts.map((product) => (
          <li key={product.id}>
            {product.name} - ${product.price}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilterAndSearch;
