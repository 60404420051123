// Cart.js
import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";
import {AiFillDelete, AiOutlineShoppingCart }  from "react-icons/ai";

const Cart = () => {
  const {
    cartItems,
    removeFromCart,
    clearCart,
    incrementQuantity,
    decrementQuantity,
    getTotalPrice,
  } = useContext(CartContext);

  const handleRemoveFromCart = (item) => {
    removeFromCart(item);
  };

  const handleClearCart = () => {
    clearCart();
  };

  const handleIncrementQuantity = (item) => {
    incrementQuantity(item);
  };

  const handleDecrementQuantity = (item) => {
    decrementQuantity(item);
  };

  // const getItemPrice = (item) => {
  //   return state.cartItems.reduce((item) =>item.price * item.quantity, 0);
  // }

  return (
    <div className="bg-white border border-gray-400 rounded-lg mx-2 md:mx-16 mt-16 p-2 md:p-10">
      <div className="text-xl pb-10 font-medium text-gray-900 items-center justify-center flex">
        Shopping cart
      </div>
   <div className="flex flex-col md:flex-row items=center justify-between gap-10">
   <div className="w-full">
    {cartItems.length === 0 ? (
       <div className="mx-auto items-center justify-center flex flex-col"> <AiOutlineShoppingCart className="h-20 w-20 "/> <p>Your cart is empty.</p></div>
      ) : (
        <ul className="-my-6 divide-y divide-gray-200">
          {cartItems.map((item) => (
            <li key={item.id} className="flex py-6">
              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                <img
                  src={item.imageSrc}
                  alt={item.imageAlt}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="ml-4 flex flex-col w-screen max-w-md">
                <div className="flex justify-between text-base font-medium text-gray-900">
                  <h3>{item.name}</h3>
                  <p className="">{item.price}</p>
                  <div className="flex flex-col md:flex-row">
                  <div className="flex items-center">
                    <button
                      className="bg-slate-300 text-slate-600 py-1 px-3 font-bold"
                      onClick={() => handleIncrementQuantity(item)}
                    >
                      +
                    </button>
                    <p className="text-gray-500 px-2">{item.quantity}</p>
                    <button
                      className="bg-slate-300 text-slate-600 py-1 px-3 font-bold"
                      onClick={() => handleDecrementQuantity(item)}
                    >
                      -
                    </button>
                  </div>
                  </div>
                </div>
                <p className="mt-1 text-sm text-gray-500">{item.color}</p>

                <div className="flex flex-1 items-end justify-between text-sm">
                  <p className="text-gray-500">Qty {item.quantity}</p>
                  <div className="flex">
                   <AiFillDelete
                      type="button"
                      className="w-6 h-6 font-medium text-red-800 hover:text-red-500"
                      onClick={() => handleRemoveFromCart(item)}
                    />
                     
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
     <div className="bg-slate-300 py-16 h-full px-4 rounded-xl mx-2 ">
     <div className="bg-slate-100 rounded-lg max-w-none md:max-w-md w-full md:w-screen border-t border-gray-200 px-4 py-6 md::px-6">
        <div className="flex justify-between text-base font-medium text-gray-900">
          <p>Subtotal</p>
          <p>N {getTotalPrice()}.00</p>
        </div>
        <p className="mt-0.5 text-sm text-gray-500">
          Shipping and taxes calculated at checkout.
        </p>
        <div className="mt-6">
          <a className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
            Checkout
          </a>
        </div>
      </div>
     </div>
   </div>
    </div>
  );
};

export default Cart;
