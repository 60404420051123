const callouts = [
    {
      name: 'MOBILE PHONES',
      description: 'Brand New Andriod, Apple and Used Phones',
      imageSrc: 'https://media.istockphoto.com/id/1394347332/photo/african-woman-using-a-cellphone-in-an-office-alone.webp?b=1&s=170667a&w=0&k=20&c=-C_TOwLDOmxKLT1T_dEK2PYq6uHCueXTXjvwiYICBsM=',
      imageAlt: 'Mobile phones collection.',
      href: '#phones',
    },
    {
      name: 'COMPUTERS',
      description: 'Brand New and Used Laptops, Desktop, Servers',
      imageSrc: 'https://images.unsplash.com/photo-1566647387313-9fda80664848?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGNvbXB1dGVyc3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60https://tailwindui.com/img/ecommerce-images/home-page-02-edition-02.jpg',
      imageAlt: 'Computer sample image.',
      href: '#computer',
    },
    {
      name: 'ACCESSORIES',
      description: 'Mouse, Keyboard, Power Adapter, Chargers, Flash-drive',
      imageSrc: 'https://images.unsplash.com/photo-1596207891316-23851be3cc20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y29tcHV0ZXIlMjBhY2Nlc3Nvcmllc3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60',
      imageAlt: 'Accessories image.',
      href: '#accessory',
    },
  ]
  
  export default function Brands() {
    return (
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <h2 className="text-2xl font-bold text-gray-900">Collections</h2>
  
            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <a href={callout.href}>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base font-semibold text-gray-900">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }