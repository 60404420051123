import React from 'react';
import { Link } from 'react-router-dom';
import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin, TiSocialInstagram,} from "react-icons/ti";

const Footer = () => {
  const today = new Date();
  return (
    <div className="relative isolate items-center justify-center gap-x-6 overflow-hidden bg-gray-50 px-6 mt-16">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div className='flex flex-col md:flex-row md:justify-between px-3 md:px-28 pt-15 space-y-6 md:space-y-0 py-16'>
        <div className="flex flex-col text-gray-900 text-xs md:text-sm md:font-medium space-y-4">
        <div className="ml-4 flex items-baseline  gap-2">
                  <Link to="/" className="flex gap-2 items-center">
                    <img src="./oplogo.png" alt="Company Logo" className="flex h-[60px] w-[60px] "/>
                    <p className="text-xl md:text-2xl font-bold cursor-pointer text-[#e2312b]">
                     Optasiaplus
                    </p>
                  </Link>
                </div>
            <div className="max-w-md">
            <p className="text-md ">Computers, Mobile Phones, Computer Peripherals and Accessories Maintenance Services, Networking and security consulting services.</p>
            </div>
            <div className="max-w-md">
            <p className="text-md ">All Naira payment credit/debit cards supported.</p>
            <div className='bg-transparent p-2 mt-2 flex justify-center'>
            <img src='/images/secure.png' alt='' className='w-35 h-20' />
            </div>
            
            </div>
        </div>
      
      <div className="flex flex-col text-gray-900 text-xs md:text-sm md:font-medium space-y-4">
            <div className='border-b border-gray-400 divide-y-8 text-orange-300 text-lg font-semibold'>
              <p className='py-2'>Company</p>
            </div>
            {/* <a 
              href="/company" 
              className="text-base transition ease-in-out delay-150 hover:text-gray-50 hover:translate-x-4 duration-300"
            >
              About us
            </a> */}
            <a 
              href="/Company" 
              className="text-base transition ease-in-out delay-150 hover:text-gray-50 hover:translate-x-4 duration-300"
              
            >
              Privacy policy
            </a>
            <a 
              href="/comapany" 
              className="text-base transition ease-in-out delay-150 hover:text-gray-50 hover:translate-x-4 duration-300"
            >
              Terms of service
            </a>
            <a
              href="/company" 
              className="text-base transition ease-in-out delay-150 hover:text-gray-50 hover:translate-x-4 duration-300"
            >
             Refund policy 
            </a>
            
      </div>
      
      <div className='flex flex-col text-xs md:text-sm md:font-medium space-y-4 pb-4'>
            <div className='border-b border-gray-400 divide-y-8 text-orange-300 font-semibold'>
              <p className='py-2 text-lg' >Socials</p>
            </div>
            <div className='flex item-center gap-3 text-gray-900 text-2xl cursor-pointer'>
                <TiSocialFacebook />
                <TiSocialTwitter />
                <TiSocialLinkedin />
                <TiSocialInstagram />
                
            </div>
            <div className='border-b border-gray-500 divide-y-8 text-orange-300 font-semibold'>
              <p className='py-2 text-lg' >Contacts</p>
            </div>
            <a href="https://optasiaplus.ng" className="text-gray-900">support@optasiaplus.ng</a>
            <a href="https://optasiaplus.ng" className="text-gray-900">+234-809-990-0042, +234-803-767-3402</a>

      </div>
      </div>
      <div className="flex flex-row items-center justify-center text-md md:text-lg font-bold tracking-tight text-gray-700 py-4">
          <a href='/'>Optasiaplus &copy; {today.getFullYear()}</a> 
      </div>
    </div>
  )
}


export default Footer