import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";


const ProductList = () => {
  const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  const products = [
    { id: 1, name: "Product 1", price: 9.99 },
    { id: 2, name: "Product 2", price: 19.99 },
    { id: 3, name: "Product 3", price: 14.99 },
    { id: 4, name: "Product 4", price: 18.99 },
  ];

  const handleAddToCart = (product) => {
    addToCart({ ...product, quantity: 1 });
  };

  const handleRemoveFromCart = (product) => {
    removeFromCart(product);
  };

  return (
    // <div className='max-w-2xl  '>
    //   <h2>Products</h2>
    //   <ul>
    //     {products.map((product) => (
    //       <li className='flex items-center justify-between' key={product.id}>
    //         {product.name} - ${product.price}

    //       </li>
    //     ))}
    //   </ul>

    // </div>

    <div className="bg-white border border-gray-200 rounded-lg m-4 mx-2 md:mx-16 mt-10">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          Laptops and Desktops
        </h2>

        <div className="mt-6 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <div key={product.id} className="">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href={product.href}>
                      
                      {product.name}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                </div>
                <p className="text-sm font-medium text-gray-900">
                  N{product.price}
                </p>
              </div>
           
                {cartItems.some((p) => p.id === product.id) ? (
                  <button className="bg-red-800 w-full py-3 text-slate-100 font-bold text-center hover:bg-red-600" onClick={() => handleRemoveFromCart(product)}>
                    Remove
                  </button>
                ) : (
                  <button className="bg-slate-700 w-full py-3 text-slate-100 font-bold text-center hover:bg-slate-400" onClick={() => handleAddToCart(product)}>
                    Add to Cart
                  </button>
                )}
            
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
