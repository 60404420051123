import React, { useContext } from 'react';
import { CartContext } from '../context/CartContext';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';


const phones = [
    { id: 5, name: "phone 1", price: 9.99 },
    { id: 6, name: "phone 2", price: 19.99 },
    { id: 7, name: "phone 3", price: 14.99 },
    { id: 8, name: "phone 4", price: 18.99 },
    { id: 9, name: "phone 5", price: 19.99 },
    { id: 10, name: "phone 6", price: 14.99 },
    { id: 11, name: "phone 7", price: 18.99 },
  ];

const Phones = () => {
    const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  
    const handleAddToCart = (phone) => {
      addToCart({ ...phone, quantity: 1 });
    };
  
    const handleRemoveFromCart = (phone) => {
      removeFromCart(phone);
    };

    const slideLeft = () => {
        var slider = document.getElementById ('slider');
        slider.scrollLeft = slider.scrollLeft - 500;
    };

    const slideRight = () => {
        var slider = document.getElementById ('slider');
        slider.scrollLeft = slider.scrollLeft + 500;
    };


  return (
    <div className='bg-slate-200 w-full my-24 py-24'>
        
       <h2 className='p-4 text-md md:text-xl font-bold mt-2'>Phones & Tablets</h2>
       <div className='relative flex flex-row items-center group'>
        <MdChevronLeft onClick={slideLeft} size={40} className='absolute z-[10] left-0 bg-white rounded-full opacity-50 hover:opacity-100 hidden group-hover:block ' />
        <div id={'slider'} className='w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative gap-x-4'>
        {phones?.map((item) => (
            <div key={item.id}
            className='w-[160px] sm:w-[200] md:w-[240px] relative inline-block p-2 bg-slate-100 rounded-md mr-4'>
            <img className="w-[160px] sm:w-[200] md:w-[240px] h-[120px] block bg-white rounded-md shadow-sm" src={item.image} alt={item.imageAlt} />
        
           <div className='flex items-center justify-between px-2 py-2'>
           <p className='text-gray-700 '>{item.name}</p>
            <p className='text-gray-900 indent-4 '>N {item.price}</p>
           </div>
           {cartItems.some((p) => p.id === item.id) ? (
                  <button className="bg-red-800 w-full py-3 text-slate-100 font-bold text-center hover:bg-red-400" onClick={() => handleRemoveFromCart(item)}>
                    Remove
                  </button>
                ) : (
                  <button className="bg-slate-700 w-full py-3 text-slate-100 font-bold text-center hover:bg-slate-400" onClick={() => handleAddToCart(item)}>
                    Add to Cart
                  </button>
                )}
        </div>
        ))}
        </div>
        <MdChevronRight onClick={slideRight} size={40} className='absolute z-[10] right-0 bg-white rounded-full opacity-50 hover:opacity-100 hidden group-hover:block' />
       </div>
      
    </div>
  )
}

export default Phones;
